import type { UrlObject } from 'url'

import React, { useCallback, useEffect, useMemo } from 'react'
import isEqual from 'react-fast-compare'
import type { Hit } from 'react-instantsearch-core'
import classnames from 'classnames'

import { useDispatch, useSelect } from 'store/index'

import { setMapInfowindowClicked, setSelectedHit } from 'reducers/uiState'

import useAugmentedRouter from 'hooks/useAugmentedRouter'
import useAnalytics from 'hooks/useAnalytics'
import { useSegmentAnonymousId } from 'hooks/useSegmentAnonymousId'

import CustomLink from 'components/Link/CustomLink'
import StarRatings from 'components/StarRatings/StarRatings'
import ResultCarousel from 'components/Carousels/ResultCarousel'
import FavoriteButton from 'components/Favorites/FavoriteButton'
import NoDatePricing from 'components/Search/Search-Results/NoDatePricing'

import style from './Result.module.scss'

import { avgNightCopy, taxesCopy, totalPriceCopy } from 'config/Search'
import { TabletBreakpoint } from 'config/Breakpoints'

import { calcRating, getCookieByName, setPrice } from 'utils/Strings'
import { buildListingLink } from 'utils/Listings'
import { pushToDataLayer } from 'utils/Gtm'
import debounce from 'utils/debounce'
import { isOnServerSide } from 'utils/validations'

import ComingSoon from 'assets/icons/icon-coming-soon-md.svg'

import type {
  AlgoliaAnalyticsPayload,
  AlgoliaListing,
} from 'types/externalData'

declare global {
  interface Window {
    aa: any
  }
}

type AlgoliaResultProps = {
  hit: Hit<AlgoliaListing>
  resultIndex: number
  totalPrice: number
  calculatePrice: (hit: AlgoliaListing) => number
  displayPrice?: number
}

const AlgoliaResult = ({
  hit,
  resultIndex,
  totalPrice,
  calculatePrice,
}: AlgoliaResultProps) => {
  const { clickListing } = useAnalytics()
  const { query } = useAugmentedRouter()
  const width = useSelect((state) => state.uiState.width)
  const anonymousId = useSegmentAnonymousId()

  const mapInfowindowClicked = useSelect(
    (state) => state.uiState.mapInfowindowClicked,
  )
  const startDate = useSelect((state) => state.search.selectedDates?.start)
  const endDate = useSelect((state) => state.search.selectedDates?.end)
  const activeView = useSelect((state) => state.uiState.activeView)
  const calculatedRating = calcRating(hit['Average Rating'])
  const hasDates = useMemo(
    () => (startDate && endDate ? true : false),
    [endDate, startDate],
  )
  const isPriceValid = !isNaN(totalPrice)
  const hasDatesAndPrice = hasDates && isPriceValid

  const appDispatch = useDispatch()

  const handleListingClick = useCallback(() => {
    const pageNumber = parseInt(query.page?.toString() || '1')
    const actualPosition = hit.__position || 1
    const position = actualPosition - (pageNumber - 1) * 20
    const userToken = anonymousId || getCookieByName('_ALGOLIA')

    clickListing({
      ...hit,
      query: window.location.href.split('?')[1] || '',
      price: calculatePrice(hit),
      searchPageNumber: pageNumber,
      position,
    })

    if (!isOnServerSide) {
      const aaPayload: AlgoliaAnalyticsPayload = {
        index: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX!,
        eventName: 'View Listing',
        objectIDs: [hit.objectID],
        positions: [hit.__position],
        queryID: hit.__queryID,
      }
      if (userToken) {
        aaPayload.userToken = userToken
      }
      window.aa('clickedObjectIDsAfterSearch', aaPayload)
    }

    pushToDataLayer('select_item', {
      ecommerce: {
        items: [
          {
            item_id: hit.objectID,
            item_name: hit.Headline,
            item_category: hit['Property Type'],
            location_id: hit.__position,
            price: hit['Average Per Night'],
          },
        ],
      },
    })
  }, [anonymousId, calculatePrice, clickListing, hit, query.page])

  const renderPrice = useCallback(() => {
    return (
      <span className={style.avgPrice}>
        {hasDatesAndPrice ? (
          <>
            <div className={style.avgPriceWrapper}>
              <b className={`${style.boldPrice} ${style.smallCopy}`}>
                {`${setPrice(calculatePrice(hit))}`} {avgNightCopy}
              </b>
            </div>
            <b className={style.boldPrice}>{setPrice(Math.ceil(totalPrice))}</b>
            <span className={style.boldPrice}>
              {hasDates ? totalPriceCopy + taxesCopy : avgNightCopy}
            </span>
          </>
        ) : (
          <NoDatePricing />
        )}
      </span>
    )
  }, [calculatePrice, hasDates, hit, totalPrice])

  const renderBedBathCopy = (num: number, copy: string) =>
    `${num} ${copy}${num > 1 ? 's' : ''}`

  const renderResultDetail = () => (
    <>
      <div className={style.resultDetailsDescription}>
        <span className={style.resultType}>{hit['Property Type']}</span>
        <div className={style.resultDetailsDescriptionChild}>
          <span className={style.resultHeadline}>{hit['Headline']}</span>
        </div>
        <span className={style.resultLocation}>
          {hit['City']}, {hit['State']}{' '}
          {hit['Country'] !== 'US' && hit['Country']}
        </span>
      </div>
      <div className={style.resultDetailInfo}>
        <span>Sleeps {hit['Max Occupancy']}</span>
        <span>{renderBedBathCopy(hit['Bedrooms'], 'Bedroom')}</span>
        <span>{renderBedBathCopy(hit['Bathrooms'], 'Bathroom')}</span>
      </div>
      <div
        className={classnames(style.resultDetailBottom, style.hasDates, {
          [style.alignEnd]: hasDates,
        })}
      >
        {renderPrice()}
        {calculatedRating > 0 && (
          <div className={`${style.resultDetailRating} ${style.hasDates}`}>
            <div className={style.starRating}>
              <span>{calculatedRating}</span>
              <StarRatings
                numberOfStars={5}
                rating={calculatedRating}
                starDimension={width < TabletBreakpoint ? '14px' : '18px'}
                starRatedColor="#F9A01F"
                starSpacing="1px"
                uniqueId="resultRating"
              />
            </div>
            <span className={style.reviews}>
              ({calcRating(hit['Number of Reviews'])})
            </span>
          </div>
        )}
      </div>
    </>
  )

  const renderImages = () => (
    <div className={style.imagesWrapper}>
      {hit.images?.length ? (
        <ResultCarousel
          hit={hit}
          mapDisplay={false}
          resultIndex={resultIndex}
        />
      ) : (
        <div className={style.comingSoon}>
          <ComingSoon className={style.comingSoonImg} />
          <span>Coming Soon!</span>
        </div>
      )}
      <span className={style.resultImageType}>{hit['Property Type']}</span>
    </div>
  )

  const handleMouseOver = debounce(
    (hitId: Hit['objectID']) => {
      appDispatch(setSelectedHit(hitId))
    },
    300,
    true,
  )

  const listingLinkFromUrl: UrlObject = useMemo(
    () => buildListingLink(hit, query),
    [hit, query],
  )

  useEffect(() => {
    if (mapInfowindowClicked === hit.objectID) {
      handleListingClick()
      appDispatch(setMapInfowindowClicked(''))
    }
  }, [appDispatch, handleListingClick, hit.objectID, mapInfowindowClicked])

  return (
    <div
      className={`${style.result}`}
      onMouseEnter={() => handleMouseOver(hit.objectID)}
      onMouseLeave={() => handleMouseOver('')}
    >
      <CustomLink
        className={`${style.resultImage} ${hasDates ? style.hasDates : ''}`}
        href={listingLinkFromUrl}
        id={`listing_${hit['objectID']}`}
        onClick={handleListingClick}
        prefetch={false}
        target="_blank"
      >
        {renderImages()}
      </CustomLink>
      {activeView !== 'map' && (
        <FavoriteButton container="algoliaResult" listing={hit} />
      )}
      <CustomLink
        className={style.resultDetails}
        href={listingLinkFromUrl}
        id={`listing_${hit['objectID']}`}
        onClick={handleListingClick}
        prefetch={false}
        target="_blank"
      >
        {renderResultDetail()}
      </CustomLink>
    </div>
  )
}

export default React.memo(AlgoliaResult, isEqual)
