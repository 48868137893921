import { closeDatePicker, openDatePicker } from 'reducers/uiState'

import style from './Result.module.scss'

import DollarSignIcon from 'assets/icons/DollarSign.svg'

import { useDispatch, useSelect } from 'store'

const NoDatePricing = () => {
  const appDispatch = useDispatch()
  const isCalendarOpen = useSelect((state) => state.uiState.datePickerFocused)

  const onClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    appDispatch(isCalendarOpen ? closeDatePicker() : openDatePicker())
  }

  return (
    <button className={style.noDatePricingInfo} onClick={onClick} role="button">
      <DollarSignIcon />
      <span>Add dates for pricing info</span>
    </button>
  )
}

export default NoDatePricing
