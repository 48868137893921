import React from 'react'

import { useSelect } from 'store/index'
import { searchSelectors } from 'store/search'

import styles from './No-Results.module.scss'

import ResetFiltersButtons from '../ResetFilterButtons/ResetFiltersButtons'

const DEFAULT_NOT_FOUND_MESSAGE =
  'There are no results that match your search criteria. Try expanding your search area or removing filters'

const NoResultsText = ({ message }: { message?: string }) => (
  <>
    <h2>No results found</h2>
    <p>
      {message ? (
        <>
          <b>Please note: </b>
          {message}
        </>
      ) : (
        DEFAULT_NOT_FOUND_MESSAGE
      )}
    </p>
  </>
)

const NoResultsButtons = () => {
  const filtersCount = useSelect(searchSelectors.getSelectedFiltersCount)
  const selectedDates = useSelect((state) => state.search.selectedDates)

  return (
    <>
      <ResetFiltersButtons>
        {!!filtersCount && (
          <ResetFiltersButtons.Filters
            filtersLabel="Remove filters"
            initializedFrom="No Results"
          />
        )}
        {selectedDates?.start && selectedDates?.end && (
          <ResetFiltersButtons.Dates
            initializedFrom="No Results"
            selectedDates={selectedDates}
          />
        )}
      </ResetFiltersButtons>
    </>
  )
}

const NoResults = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  return <div className={styles.noResults}>{children}</div>
}

NoResults.Text = NoResultsText
NoResults.Buttons = NoResultsButtons

export default NoResults
