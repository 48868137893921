import React from 'react'
import { useRouter } from 'next/router'

import { useDispatch, useSelect } from 'store/index'
import {
  resetSelectedDates,
  setAndApplyDatesToFilters,
  setShouldApplyFilters,
  updateSearchFiltersString,
} from 'store/search'
import { selectedFiltersNames } from 'store/search/search.selectors'

import useAnalytics from 'hooks/useAnalytics/useAnalytics'
import { InitializedFrom } from 'hooks/useAnalytics/events/clearSearchClicked'

import style from './ResetFiltersButtons.module.scss'

import { removeQueryParams } from 'utils/Search'

import CloseIcon from 'assets/icons/icon-close.svg'

const filtersToClear = [
  'minBedrooms',
  'minBeds',
  'minBathrooms',
  'minLOS_PriceAverages',
  'maxLOS_PriceAverages',
  'minAverage-Per-Night',
  'maxAverage-Per-Night',
  'Property-Type',
  'amenities.Amenities',
  'amenities.Location',
  'amenities.View',
  'amenities.Accessibility',
  'pets',
]

type FilterButton = {
  filtersLabel?: string
  initializedFrom: InitializedFrom
}

const FiltersButton = ({ filtersLabel, initializedFrom }: FilterButton) => {
  const router = useRouter()
  const appDispatch = useDispatch()
  const { clearSearchClicked } = useAnalytics()
  const selectedFilterNames = useSelect(selectedFiltersNames)

  return (
    <button
      data-testid="resetFiltersBtn"
      onClick={() => {
        removeQueryParams(filtersToClear, router)
        appDispatch(resetSelectedDates())
        appDispatch(updateSearchFiltersString())
        clearSearchClicked({
          ...selectedFilterNames,
          initializedFrom,
          clickedFrom: 'Clear Filters',
        })
      }}
    >
      {filtersLabel ?? 'Clear filters'}
      {!filtersLabel && (
        <CloseIcon fill="currentColor" height="12" width="12" />
      )}
    </button>
  )
}

type DatesButton = {
  datesLabel?: string
  initializedFrom: InitializedFrom
  selectedDates: {
    start: string
    end: string
  }
}

const DatesButton = ({
  datesLabel = 'Clear dates',
  initializedFrom,
  selectedDates,
}: DatesButton) => {
  const appDispatch = useDispatch()
  const { clearSearchClicked } = useAnalytics()

  return (
    <button
      onClick={() => {
        clearSearchClicked({
          selectedDates,
          initializedFrom,
          clickedFrom: 'Clear Dates',
        })
        appDispatch(
          setAndApplyDatesToFilters({
            start: '',
            end: '',
          }),
        )
        appDispatch(setShouldApplyFilters(true))
      }}
    >
      {datesLabel}
    </button>
  )
}

const ResetFiltersButtons = ({ children }) => {
  return <div className={style.resetButtons}>{children}</div>
}

ResetFiltersButtons.Dates = DatesButton
ResetFiltersButtons.Filters = FiltersButton

export default ResetFiltersButtons
